// src/app/services/screen-record-detection.service.ts
import { Injectable, Renderer2, RendererFactory2, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScreenRecordingService implements OnDestroy {
  private renderer: Renderer2;
  private blackScreen: HTMLElement;
  private watermark: HTMLElement;
  private keyCombinationPressed = {
    meta: false,
    shift: false,
    win: false
  };

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.blackScreen = this.renderer.createElement('div');
    this.watermark = this.renderer.createElement('div');
    this.addKeyboardListeners();
   // this.detectFullScreenMode();
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.handleKeydown.bind(this));
    document.removeEventListener('keyup', this.handleKeyup.bind(this));
    document.removeEventListener('contextmenu', this.handleContextMenu.bind(this));
    document.removeEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
  }

  // detectScreenRecording() {
  //   navigator.mediaDevices.getDisplayMedia({ video: true }).then((stream) => {
  //     if (stream.getVideoTracks().length) {
  //       this.overlayBlackScreen();
  //     }
  //   }).catch((error) => {
  //     console.error('Error accessing display media.', error);
  //   });
  // }

  overlayBlackScreen() {
    this.renderer.setStyle(this.blackScreen, 'position', 'fixed');
    this.renderer.setStyle(this.blackScreen, 'top', '0');
    this.renderer.setStyle(this.blackScreen, 'left', '0');
    this.renderer.setStyle(this.blackScreen, 'width', '100%');
    this.renderer.setStyle(this.blackScreen, 'height', '100%');
    this.renderer.setStyle(this.blackScreen, 'backgroundColor', 'black');
    this.renderer.setStyle(this.blackScreen, 'zIndex', '9999');
    this.renderer.appendChild(document.body, this.blackScreen);
    this.addWatermark();
    this.preventRightClick();
  }

  removeBlackScreen() {
    if (this.blackScreen.parentNode) {
      this.renderer.removeChild(document.body, this.blackScreen);
    }
  }

  addKeyboardListeners() {
    document.addEventListener('keydown', this.handleKeydown.bind(this));
    document.addEventListener('keyup', this.handleKeyup.bind(this));
  }

  preventRightClick() {
    document.addEventListener('contextmenu', this.handleContextMenu.bind(this));
  }

  addWatermark() {
    this.renderer.setStyle(this.watermark, 'position', 'fixed');
    this.renderer.setStyle(this.watermark, 'top', '50%');
    this.renderer.setStyle(this.watermark, 'left', '50%');
    this.renderer.setStyle(this.watermark, 'transform', 'translate(-50%, -50%)');
    this.renderer.setStyle(this.watermark, 'color', 'white');
    this.renderer.setStyle(this.watermark, 'fontSize', '24px');
    this.renderer.setStyle(this.watermark, 'opacity', '0.5');
    this.renderer.setStyle(this.watermark, 'zIndex', '10000');
    this.renderer.setStyle(this.watermark, 'pointerEvents', 'none'); // Prevent interactions
    this.renderer.setProperty(this.watermark, 'innerHTML', '© Refresh the Page');
    this.renderer.appendChild(document.body, this.watermark);
  }
  
  detectFullScreenMode() {
    document.addEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
  }

  handleFullScreenChange() {
    if (document.fullscreenElement) {
      this.overlayBlackScreen();
    } else {
      this.removeBlackScreen();
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Meta') {
      this.keyCombinationPressed.meta = true;
    } else if (event.key === 'Win') {
      this.keyCombinationPressed.win = true;
    }

    if (this.keyCombinationPressed.meta || this.keyCombinationPressed.win) {
      this.overlayBlackScreen();
    }
  }

  handleKeyup(event: KeyboardEvent) {
    if (event.key === 'Meta') {
      this.keyCombinationPressed.meta = false;
      if (!this.keyCombinationPressed.shift && !this.keyCombinationPressed.win) {
        this.removeBlackScreen();
      }
    } else if (event.key === 'Shift') {
      this.keyCombinationPressed.shift = false;
    } else if (event.key === 'Win') {
      this.keyCombinationPressed.win = false;
      if (!this.keyCombinationPressed.meta && !this.keyCombinationPressed.shift) {
        this.removeBlackScreen();
      }
    }
  }

  handleContextMenu(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
