import { Route } from '@angular/router';
import { InitialDataResolver } from './app.resolvers';
import { MainComponent } from './pages/main/main.component';
import { AuthGuestGuard } from './shared/utils/auth-guest.guard';
import { AuthGuard } from './shared/utils/auth.guard';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardNew } from './shared/utils/auth.guardnew';
export const routes: Route[] = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  }, {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuardNew],
    resolve: {
      initialData: InitialDataResolver,
    },


    children: [

      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
      { path: '', loadChildren: () => import('./pages/Products/Products.module').then(m => m.ProductsModule), data: { breadcrumb: 'All Products' } },
      { path: '', loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule), data: { breadcrumb: 'Product' } },
      { path: '', loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule), data: { breadcrumb: 'Category' } },
      { path: '', loadChildren: () => import('./pages/general/general.module').then(m => m.GeneralModule) },
      { path: 'coupon', loadChildren: () => import('./pages/coupon/coupon.module').then(m => m.CouponModule), data: { breadcrumb: 'Coupon' } },
      { path: '', loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule) },
      { path: 'contact-us', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'CONTACT-US' } },
      { path: 'blog', loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: 'BLOG' } },
      { path: 'catalogue', loadChildren: () => import('./pages/catalogue/catalogue.module').then(m => m.CatalogueModule) },
      { path: 'brochure', loadChildren: () => import('./pages/brochure/brochure.module').then(m => m.BrochureModule), data: { breadcrumb: 'Brochure' } },
      { path: 'booking-detail', loadChildren: () => import('./pages/doctor-detail/doctor-detail.module').then(m => m.DoctorDetailModule) },
      { path: 'checkout-appointments', loadChildren: () => import('./pages/checkout-payout/checkout-payout.module').then(m => m.CheckoutPayoutModule), data: { breadcrumb: 'Checkout' } },
      { path: 'service-plan', loadChildren: () => import('./service-plan/service-plan.module').then(m => m.ServicePlanModule) },
      { path: 'product-list-temp', loadChildren: () => import('./pages/product-list-temp/product-list-temp.module').then(m => m.ProductListTempModule) },
      { path: '', loadChildren: () => import('./pages/restaurants/restaurants.module').then(m => m.RestaurantsModule) },
      { path: 'emi-calculator', loadChildren: () => import('./pages/emi-calculator/emi-calculator.module').then(m => m.EmiCalculatorModule) },
      { path: 'purchase', loadChildren: () => import('./shared/purchase/purchase.module').then(m => m.PurchaseModule) },
      { path: 'live-price-gold', loadChildren: () => import('./pages/live-price-gold/live-price-gold.module').then(m => m.LivePriceGoldModule) }, 
  
    ]
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      { path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule), data: { breadcrumb: 'My Orders' } },
      { path: 'myappoint', loadChildren: () => import('./pages/myappoint/myappoint.module').then(m => m.MyappointModule), data: { breadcrumb: 'My AppointMents' } },
      { path: '', loadChildren: () => import('./pages/review-rating/review-rating.module').then(m => m.ReviewRatingModule) },
      { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
      { path: 'myCourse', loadChildren: () => import('./pages/my-course/my-course.module').then(m => m.MyCourseModule), data: { breadcrumb: 'My Course' } },
      { path: '', loadChildren: () => import('./pages/course-detail/course-detail.module').then(m => m.CourseDetailModule) },
      { path: 'gmeet-intro', loadChildren: () => import('./gmeet-intro/gmeet-intro.module').then(m => m.GmeetIntroModule), data: { breadcrumb: 'Meeting' } },
      { path: 'room', loadChildren: () => import('./room/room.module').then(m => m.RoomModule) },

  { path: 'join-room', loadChildren: () => import('./join-room/join-room.module').then(m => m.JoinRoomModule) },
    ]
  },

  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuestGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [

      { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Cart' } },
      { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
      { path: '', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule) },
    ]
  },


  {
    path: '',
    component: MainComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [

      { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },

    ]
  },

  { path: 'not-found', loadChildren: () => import('./pages/error404/error404.module').then(m => m.Error404Module) },
  { path: 'error/500', loadChildren: () => import('./pages/error500/error500.module').then(m => m.Error500Module) },

  { path: 'ecom-gateway', loadChildren: () => import('./pages/image/image.module').then(m => m.ImageModule) },

  { path: 'pincode', loadChildren: () => import('./theme/pincode/pincode.module').then(m => m.PincodeModule) },

  { path: 'paymentSucces', loadChildren: () => import('./payment-success/payment-success.module').then(m => m.PaymentSuccessModule) },

  { path: 'supplierRegistration', loadChildren: () => import('./pages/supplier-registration/supplier-registration.module').then(m => m.SupplierRegistrationModule) },

  { path: 'blogSection', loadChildren: () => import('./pages/home/blog-section/blog-section.module').then(m => m.BlogSectionModule) },

  { path: 'locationNotFound', loadChildren: () => import('./pages/location-not-found/location-not-found.module').then(m => m.LocationNotFoundModule) },

  { path: 'paymentSuccess', loadChildren: () => import('./pages/payment-completed/payment-completed.module').then(m => m.PaymentCompletedModule) },

  { path: 'slot', loadChildren: () => import('./pages/slot/slot.module').then(m => m.SlotModule) },
  { path: 'table', loadChildren: () => import('./pages/product/table/table.module').then(m => m.TableModule) },

  { path: 'deliveryLogin', loadChildren: () => import('./pages/delivery-login/delivery-login.module').then(m => m.DeliveryLoginModule) },

  { path: 'textEditor', loadChildren: () => import('./shared/text-editor/text-editor.module').then(m => m.TextEditorModule) },

  { path: 'phoneNumber', loadChildren: () => import('./pages/phone-number/phone-number.module').then(m => m.PhoneNumberModule) },

  { path: 'customOption', loadChildren: () => import('./pages/custom-option/custom-option.module').then(m => m.CustomOptionModule) },

  { path: 'youtubeVideo', loadChildren: () => import('./pages/home/youtube-video/youtube-video.module').then(m => m.YoutubeVideoModule) },

  { path: 'searchLocation', loadChildren: () => import('./search-location/search-location.module').then(m => m.SearchLocationModule) },

  { path: 'commonOrder', loadChildren: () => import('./shared/common-order/common-order.module').then(m => m.CommonOrderModule) },

  { path: 'course-detail-mob', loadChildren: () => import('./shared/course-detail-mob/course-detail-mob.module').then(m => m.CourseDetailMobModule) },

  { path: 'city-selection', loadChildren: () => import('./theme/city-selection/city-selection.module').then(m => m.CitySelectionModule) },

  { path: 'certificate-download', loadChildren: () => import('./pages/order/certificate-download/certificate-download.module').then(m => m.CertificateDownloadModule) },

  { path: 'viewCourse', loadChildren: () => import('./shared/view-course/view-course.module').then(m => m.ViewCourseModule) },

  { path: 'CourseRating', loadChildren: () => import('./shared/course-rating/course-rating.module').then(m => m.CourseRatingModule) },

  { path: 'restriction', loadChildren: () => import('./shared/restriction/restriction.module').then(m => m.RestrictionModule) },

  { path: 'list', loadChildren: () => import('./shared/list/list.module').then(m => m.ListModule) },

  { path: 'controlsCart', loadChildren: () => import('./shared/controls-cart/controls-cart.module').then(m => m.ControlsCartModule) },

  { path: '', loadChildren: () => import('./pages/quiz/quiz.module').then(m => m.QuizModule) },

  { path: 'subscription', loadChildren: () => import('./shared/subscription/subscription.module').then(m => m.SubscriptionModule) },

  { path: 'subscriptionPlan', loadChildren: () => import('./shared/subscription-plan/subscription-plan.module').then(m => m.SubscriptionPlanModule) },

  { path: 'notes', loadChildren: () => import('./shared/notes/notes.module').then(m => m.NotesModule) },
  { path: 'common-images', loadChildren: () => import('./pages/common-images/common-images.module').then(m => m.CommonImagesModule) },






  { path: 'new-checkout', loadChildren: () => import('./pages/layout/new-checkout/new-checkout.module').then(m => m.NewCheckoutModule) },
  
  { path: 'pdf-viewer2', loadChildren: () => import('./pages/product/pdf-viewer2/pdf-viewer2.module').then(m => m.PdfViewer2Module) },

  { path: 'meeting-left', loadChildren: () => import('./meeting-left/meeting-left.module').then(m => m.MeetingLeftModule) },

  { path: 'Invoicetemplate', loadChildren: () => import('./pages/invoicetemplate/invoicetemplate.module').then(m => m.InvoicetemplateModule) },
  
    { path: 'product-customizer', loadChildren: () => import('./product-customizer/product-customizer.module').then(m => m.ProductCustomizerModule) },

  { path: 'editor-text', loadChildren: () => import('./pages/home/editor-text/editor-text.module').then(m => m.EditorTextModule) },

  { path: '**', loadChildren: () => import('./pages/error404/error404.module').then(m => m.Error404Module) },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
