<footer [style.backgroundImage]="'url(' + serverPath1+footerBrandImg + ')'" class="footer-v1" style="max-width: 100%;">
    <div class="theme-container">
       <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around start" class="py-1 border-bottom-mute">
        <div fxFlex="10"></div>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="25">
          <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
          <div fxLayout="column" fxLayoutAlign="start start">
            <h3  class="mt-1">
              {{ data.streetAddress | uppercase }}
            </h3>
           
            <h3>Contact : {{ data.storePhoneNumber }}</h3>
          </div>
        </div>
        

        <div  fxFlex="15" >
            <!-- <h3  style="cursor: pointer;" class="mt-1"><a  (click)="gotoLink('/pages/News%20and%20Blogs') ">Home</a></h3> -->
            <h3  style="cursor: pointer;"  class="mt-1"  ><a (click)="gotoLink('/pages/Demo') ">Demo</a></h3>
            <h3  style="cursor: pointer;"  class="mt-1"  ><a (click)="gotoLink('/pages/Features') "  >Features</a></h3>
            <h3   style="cursor: pointer;" class="mt-1"  ><a  (click)="gotoLink('/pages/pricing-plans') ">Pricing</a></h3>            
            <!-- <h3 style="cursor: pointer;"   class="mt-1" > <a  (click)="gotoLink('/pages/partner') " >Partner</a></h3>             -->
        </div>
        <div  fxFlex="15" >
            <h3  style="cursor: pointer;"  class="mt-1"   ><a (click)="gotoLink('/pages/News%20and%20Blogs') ">dSPEEDUp</a></h3>
            <!-- <h3  style="cursor: pointer;" class="mt-1"  ><a (click)="gotoLink('/pages/Marketing') " >Marketing</a></h3>  -->
            <h3  style="cursor: pointer;"  class="mt-1" ><a (click)="gotoLink('/pages/dspeedup-pharma') ">Pharmacy Website</a></h3>
            <!-- <h3  style="cursor: pointer;"  class="mt-1" ><a (click)="gotoLink('/pages/crm-services') ">Crm</a></h3> -->
        </div>
        <div  fxFlex="13" >
            <!-- <h3 style="cursor: pointer;" class="mt-1"  >Careers</h3>
            <h3  style="cursor: pointer;" class="mt-1"  >Our Story</h3> -->
            <h3 style="cursor: pointer;"  class="mt-1"><a routerLink="/contact-us" >Contact Us</a></h3>
            <h3  style="cursor: pointer;" class="mt-1" > <a (click)="gotoLink('/pages/Affiliate') " >Affiliate</a></h3>
            <h3  style="cursor: pointer;" class="mt-1" > <a (click)="gotoLink('/aboutus') " >About Us</a></h3>             
        </div>
        <div  fxFlex="12">
            <h3 *ngIf="data.showBlogPage==1"  class="mt-1"><a routerLink="/blog" >Blog</a></h3>
            <!-- <h3  style="cursor: pointer;"  class="mt-1" ><a (click)="gotoLink('/pages/faq') ">Faq's</a></h3> -->
            <h3   style="cursor: pointer;" class="mt-1"><a routerLink="/privacy" >Privacy Policy </a></h3>            
        </div>

        <div fxFlex="10"></div>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" class="border-bottom-mute">
        <div fxFlex="10"></div>
        <div fxFlex.lt-md="50" fxFlex.gt-md="30">
            <div *ngIf="showFooterIcon==1">
                <span *ngFor="let item of footerIconData; let i = index">
          
                  <button (click)="navigate(item)" mat-icon-button>
          
                    <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                  </button>
                </span>
          
              </div>
        </div>
        <div fxFlex.lt-md="40" fxFlex.gt-md="60">
            <h3 class="mt8">© CALIFORNIA SOFTWARE COMPANY LIMITED. All rights reserved.</h3>
        </div>
    </div>
    <!-- <p class="mt-1" style="text-align: center;"><a routerLink="/terms&conditions" >Terms & Conditions</a></p> -->

      </div>
    
      <!-- <div *ngIf="footerImageEnable==1">
      <div *ngIf="display">
        <div style="margin-top:auto;" fxHide.lt-md>
          <img [src]="serverPath+footerLogoSrc" onerror="this.src='';" style="width: 100%;"/>
        </div>
      </div>
      </div> -->
      
    </footer>